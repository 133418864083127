.layout-wrapper-static {
  .layout-main.customer {
    margin-left: 0px;

    .menu-btn{
      display: inline-block;
    }
  }
  .layout-main {
    .layout-topbar.customer {
      width: 100%;
      padding: 0;
      background: none;
      margin-top: -5px;

      .topbar-header{
        padding: 15px 0;

        .topbar-header-title{
          margin-bottom: 0;
        }
        .topbar-header-branding{
          margin-bottom: 0;
        }
      }

      .topbar-action{
        height: 58px;
        background-color: #e6e6e6;
        padding: 10px 5px 10px 5px;
        text-align: center;

        .p-link.topbar-menu-btn{
          color: #5e5e5e;
          border-color: none;

          &:enabled:not(:focus):not(.p-dataview-header .p-highlight):hover {
            background: none;   
          }
        
          &:focus {
              outline: 0 none;
              background: none;
          }
        }
      }
    }
  } 
}

.btn-payment{
  // margin-bottom: 50px;
  background: linear-gradient(to top right, #5E245E, #7F4F7F) !important;
  color: white !important;
  border-radius: 30px !important;
  // box-shadow: none !important;
  font-size: 16px !important;
  @media (max-width:1024PX) {
    font-size: 22px !important;
  }
  @media (max-width:768PX) {
    font-size: 18px !important;
  }
  @media (max-width:576PX) {
    font-size: 14px !important; 
  }
}
.btn-edit-delete{
  color: white;
  border-radius: 100% !important;
}
.btn-place-order {
  // margin-top: 10px !important;
  font-size: 20px;
  border-radius: 40px !important;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(to top right, #5E245E, #7F4F7F) !important;
  float: right;
  color: white !important;
  @media (max-width:1024PX) {
    font-size: 22px !important;
  }
  @media (max-width:768PX) {
    font-size: 18px !important;
  }
  @media (max-width:576PX) {
    font-size: 13px !important; 
  }
}
.btn-request-bill {
  // margin-top: 10px !important;
  font-size: 16px;
  border-radius: 40px !important;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(to top right, #5E245E, #5E245E) !important;
  float: right;
  color: white !important;
  @media (max-width:1024PX) {
    font-size: 22px !important;
  }
  @media (max-width:768PX) {
    font-size: 18px !important;
  }
  @media (max-width:576PX) {
    font-size: 14px !important; 
  }
}
.page-title{
  margin: unset;
  font-size: 22px;
  @media (max-width:1024PX) {
    font-size: 32px;
  }
  @media (max-width:768px) {
    font-size: 26px;
  }
  @media (max-width:576PX) {
    font-size: 20px;
  }
}
.table-name{
  margin-bottom: unset;
  font-size: 22px;
  font-weight: normal;
  @media (max-width:1024PX) {
    font-size: 32px;
  }
  @media (max-width:768px) {
    font-size: 26px;
  }
  @media (max-width:576PX) {
    font-size: 20px;
  }
}
.table-title{
  font-size: 22px;
  margin-bottom: -15px;
  @media (max-width:1024PX) {
    font-size: 30px;
  }
  @media (max-width:768PX) {
    font-size: 24px;
  }
  @media (max-width:576PX) {
    font-size: 18px;
  }
}
.food-detail, .cate-title{
  font-size: 18px;
  @media (max-width:1024PX) {
    font-size: 24px;
  }
  @media (max-width:768PX) {
    font-size: 20px;
  }
  @media (max-width:576PX) {
    font-size: 16px;
  }
}
.cate-title{
  font-weight: bold;
  color: black;
}
.line-bottom{
  margin-bottom: 70px;
}
.layout-wrapper {
  .layout-main.customer {
    .layout-topbar.customer {
      z-index: 999;
    }

    .layout-content {
      padding: 0;
    }

    // .layout-content>div {
    //   padding: calc(.layout-topbar.customer) 65px 15px 0px 15px;
    // }
  }
}
.apply-coupon{
  .p-inputgroup{
   
    .p-inputgroup-addon{
      // border: none;
      border: 0px !important;
      outline: 0 none !important;
    }
  }
}
.btn-apply-voucher{
  background: linear-gradient(to top right, #5E245E, #7F4F7F) !important;
  color: white !important;
  border-radius: 20px !important;
  margin-left: 10px !important;
}

.btn-cancel-voucher{
  background: linear-gradient(to top right, #F44336, #FF5722) !important;
  color: white !important;
  border-radius: 20px !important;
  margin-left: 10px !important;
}

.box-total{
  border: 1px solid #7F4F7F;
  border-radius: 5px;
  background-color:#7F4F7F;
  color: white;
  padding-top: 7px;
  padding-bottom: 7px;
}
.topbar-menu-btn.customer {
  display: inline-block!important;
}

body .p-growl.p-growl-topright,
body .p-growl.p-growl-topleft {
    top: 90px;
    .p-growl-icon-close{
        &:before {
            content: '';
        }
        .p-growl-icon-close-icon{
            font-size: 1em;
        }
    }
}

body .p-sidebar {
    .p-sidebar-close {
      outline: none;
      .p-sidebar-close-icon {
          font-size: 3em;
          outline: none;
      }
    }
}

// .topbar-menu.topbar-menu-active.customer{
//   right: 22px!important;
// }

.c-menu{ 
  overflow-x: hidden;
  .c-menu-wrapper {
    overflow-x: auto;
    display: flex;
    .c-menu-item-wrapper {
      margin: 0px 3px 0px 0px;
      display: inline-flex;
      white-space: nowrap;
      .c-menu-item {
        margin-bottom: 5px;
        background-color: #fff;
        margin-top: 16px;
        font-size: 16px;
        color: #2d333f;
        line-height: 20px;
        font-weight: 500;
        border: 1px solid #d8d9db;
        border-radius: 2px;
        padding: 8px 16px;
        font-family: 'Oswald',sans-serif;
        display: inline-block;
        box-sizing: border-box;
        &:focus{
          outline: unset;
        }
      }
  
      .c-menu-item-active {
        font-weight: bold;
        border: 2px solid #da3743;
        padding: calc(8px - 1px) calc(16px - 1px);
        outline: unset;
      }
    }
  }
}

.c-cate-wrapper {
  margin: 15px 0px;
  display: block;

  .c-cate-item {
    margin-bottom: 20px;

    h1 {
      display:inline-block;
      // color: #da3743;
      text-transform: uppercase;
      font-size: 18px;
      font-family: 'Oswald',sans-serif;
      font-weight: bold;
      position:relative;
    }
    
    // h1:after {
    //   content:'';
    //   position:absolute;
    //   top:100%;
    //   left:0;
    //   width:65%;
    //   border-bottom: 3px solid #da3743;
    // }

    p {
      margin-top: 0;
    }
  }
}

.c-food-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .c-food-item {
    word-wrap: break-word;
    box-sizing: border-box;
    flex: 0 0 auto;
    width: 100%;
    word-wrap: break-word;
    margin-bottom: 10px;
    outline: 1px solid #d8d8d8;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 1px 10px 0 rgba(0, 0, 0, 0.04), 0 4px 5px 0 rgba(0, 0, 0, 0.1);
    padding: 10px;

    @media only screen and (min-width: 600px) and (max-width: 1024px) {
      width: calc(50% - 11px);
    }

    @media only screen and (min-width: 1025px) {
      width: calc(33.33% - 14.66px);
    }

    h2 {
      margin: 5px 0px;
      font-family: 'Oswald',sans-serif;
      font-weight: bold;
      font-size: 15px;
    }

    .c-food-item-desc {
      overflow-y: auto;
      text-overflow: ellipsis;
      white-space: pre-line;
      display: -webkit-box;
      max-height: 54px;
      -webkit-box-orient: vertical;
    }
    
    .c-menu-item-discount {
      font-size: 14px;
      padding: 0px;
      margin: 5px;
      color: #322EE6;
    }
  }
}

.c-food-empty-col{
  width: 22px;
}

.processing-section{
  .panel-card{
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 1px 10px 0 rgba(0, 0, 0, 0.04), 0 4px 5px 0 rgba(0, 0, 0, 0.1);
    background-color: white;
    // margin:10%;
    padding-top: 5%;
    border-radius: 20px;
    
    img{
      height:100px;
    }

    p{
      font-size: large;
    }
    .p-button{
      color: white;
      margin: 7.5px;
    }
    .p-button.p-button-warning{
      background: transparent !important ;
      border: 1.5px solid #7F4F7F !important;
      color: #7F4F7F !important;
      &:hover{
        background: #7F4F7F !important;
        color: white !important;
      }
    }
  }
  
}
@media only screen and (max-width: 599px) {
  .c-food-empty-col{
    width: 0;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .c-food-empty-col:nth-child(4n+4) {
    width: 0;
  }
}

@media only screen and (min-width: 1025px) {
  .c-food-empty-col:nth-child(6n+6) {
    width: 0;
  }
}


.input-quantity .p-button.p-button-icon-only .pi {
  margin-left: 0px!important;
}

.btn-xxs{
  padding: 1px 5px!important;
  font-size: 8px!important;
  line-height: 1.5!important;
}

.input-quantity .p-inputnumber-input{
  text-align: center;
  width: 100%;
}

.p-button.no-radius {
  border-radius: 0px!important;
}

.p-button.p-button-language{
  padding: 0px 5px;
}

.basket-count{
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}

.basket-count[data-count]:after{
  position:absolute;
  right:-18px;
  top:-5px;
  content: attr(data-count);
  font-size:83%;
  font-weight: bold;
  padding:.4em;
  border-radius:999px;
  line-height:.75em;
  color: #da3743;
  text-align:center;
  min-width:1em;
  font-weight:bold;
  background: white;
  border-style:solid;
}

.p-panel.customer{
  .p-panel-titlebar{
    background-color: #fff;
    color: grey;
    border-bottom: 1px solid #d8d8d8;

    a{
      color: grey;
    }
  }

  .p-panel-title{
    font-size: 18px;
    font-family: 'Oswald',sans-serif;
    font-weight: bold;
    cursor: pointer;
  }

  a.p-panel-titlebar-icon{
    float: right;
    font-size: 18px;
    margin-right: 10px;
    color: #ffffff;

    .pi.pi-plus::before{
      content: "expand_less";
    }

    .pi.pi-minus::before{
      content: "expand_more";
    }
  }
}
.p-accordion-header-customer{
  font-size: 16px;
  font-family: 'Oswald',sans-serif;
  font-weight: bold;
}

.lang-dialog{
  @media screen and (max-width: 1024px) {
      width: 50vw;
  }
  @media screen and (min-width: 1025px) {
      width: 30vw;
  }
  .p-dialog-content{
      overflow: hidden!important;
  }
  .p-dialog-titlebar{
      text-align: center;
  }
}

.item-tag {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 10px;
  color: #ffffff;
  margin-right: 2px;
  font-weight: bold !important;
  margin-top: 2px;
}
.item-tag-blue{
  background: #7F4F7F !important;
}
.item-tag-red{
  background: #f84e67 !important;
}
.p-sidebar.customer{
  // @media screen and (max-width: 425px){
  //   width: 95%;
  // }
  width: 65%;
  @media (max-width:1024px) {
    width: 75%;
  }
  @media (max-width:768px) {
    width: 85%;
  }
  @media (max-width:576px) {
    width: 95%;
  }
}

.p-button.p-button-warning{
  background-color: #f84e67 !important;
  color: rgba(255, 255, 255, 0.9)!important;
}

.p-round{
  border-radius: 20px !important;
}

.btn-split-payment{
  background-image: linear-gradient(to top right, #F2BE0D, #F2BE0D) !important;
  color: white !important;
  border-radius: 20px !important;
  box-shadow: none !important;
}

.p-button.call-server-btn {
  .pi.pi-bell {
    font-size: 25px;
  }
}

.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;
  
  .check-icon {
      width: 80px;
      height: 80px;
      position: relative;
      border-radius: 50%;
      box-sizing: content-box;
      border: 4px solid #FFFFFF;
      
      &::before {
          top: 3px;
          left: -2px;
          width: 30px;
          transform-origin: 100% 50%;
          border-radius: 100px 0 0 100px;
      }
      
      &::after {
          top: 0;
          left: 30px;
          width: 60px;
          transform-origin: 0 50%;
          border-radius: 0 100px 100px 0;
          animation: rotate-circle 4.25s ease-in;
      }
      
      &::before, &::after {
          content: '';
          height: 100px;
          position: absolute;
          background: #FFFFFF;
          transform: rotate(-45deg);
      }
      
      .icon-line {
          height: 5px;
          background-color: #FFFFFF;
          display: block;
          border-radius: 2px;
          position: absolute;
          z-index: 10;
          
          &.line-tip {
              top: 46px;
              left: 14px;
              width: 25px;
              transform: rotate(45deg);
              animation: icon-line-tip 0.75s;
          }
          
          &.line-long {
              top: 38px;
              right: 8px;
              width: 47px;
              transform: rotate(-45deg);
              animation: icon-line-long 0.75s;
          }
      }
      
      .icon-circle {
          top: -4px;
          left: -4px;
          z-index: 10;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          position: absolute;
          box-sizing: content-box;
          border: 4px solid rgba(223, 223, 223, 0.5);
      }
      
      .icon-fix {
          top: 8px;
          width: 5px;
          left: 26px;
          z-index: 1;
          height: 85px;
          position: absolute;
          transform: rotate(-45deg);
          background-color: #FFFFFF;
      }
  }
}

@keyframes rotate-circle {
  0% {
      transform: rotate(-45deg);
  }
  5% {
      transform: rotate(-45deg);
  }
  12% {
      transform: rotate(-405deg);
  }
  100% {
      transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
      width: 0;
      left: 1px;
      top: 19px;
  }
  54% {
      width: 0;
      left: 1px;
      top: 19px;
  }
  70% {
      width: 50px;
      left: -8px;
      top: 37px;
  }
  84% {
      width: 17px;
      left: 21px;
      top: 48px;
  }
  100% {
      width: 25px;
      left: 14px;
      top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
      width: 0;
      right: 46px;
      top: 54px;
  }
  65% {
      width: 0;
      right: 46px;
      top: 54px;
  }
  84% {
      width: 55px;
      right: 0px;
      top: 35px;
  }
  100% {
      width: 47px;
      right: 8px;
      top: 38px;
  }
}

.c-food-item-cursor {
  cursor: pointer;
}

.noti-buton{

  .pi-envelope{
    font-size: 210%
  }
}

  
.full-width{
  width: 100%;
}

.floating-whatsapp-button{
  bottom: 6rem!important;
  right: 1rem!important;
}

.floating-whatsapp-chatbox{
  bottom: 10rem!important;
  right: 2rem!important;
}

.button-edit {
  background-color: #F2BE0D !important;
  color: white !important;
  border-radius: 20px !important;
  box-shadow: none !important;
}

.button-cancel {
  // add custom color here
}
