.topbar-action{
    // background: linear-gradient(to top right,#7F4F7F, #7bd5f5 );
    background: linear-gradient(to top right,#5e245e, #7e507e );
}
.topbar-menu-title {
    color: var(--theme-page-text) !important;
    font-size: 24px;
    font-weight: bolder;
    text-align: center;
    height: 0;
    margin-top: 5px;
}

.language-button{
    button{
        border-radius: 3px;
        overflow: hidden;
        font-size: 14px;
        font-family: "Roboto", "Helvetica Neue", sans-serif;
        background-color: #607D8B;
        color: #ffffff;
        padding: 0 1em;
        height: 2.5em;
        border: 0 none;
        box-shadow: 0 1px 2.5px 0 rgba(0, 0, 0, 0.26), 0 1px 5px 0 rgba(0, 0, 0, 0.16);
        transition: all 0.3s;
        vertical-align: middle;
        width: 100%;
        &:hover{
            background-color: #455A64;
        }
        &:focus{
            outline: 0 none;
            background-color: #7b96a3;
        }

        img{
            margin: auto 0px;
        }
    }
}

.lang-dialog{
    .p-dialog{
        background: whitesmoke !important;
    }
    @media screen and (max-width: 1024px) {
        width: 70vw;
    }
    @media screen and (min-width: 1025px) {
        width: 30vw;
    }
    .p-dialog-content{
        background-color: whitesmoke;
        overflow: auto!important;
    }
    .p-dialog-titlebar{
        background: whitesmoke !important; //the code not enter this
        text-align: left;//the code enter this
    }
}

.action{
    display: flex;
}

.sticky {
    position: fixed!important;
    top: 0!important;
    width: 100%!important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
}

.sticky-with-server {
    position: fixed!important;
    top: 0!important;
    width: 100%!important;
}

.sticky-server {
    position: fixed!important;
    width: 100%!important;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 10px 0 rgba(0, 0, 0, 0), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
    height: 32px!important;
}

.customer-action-menu {
    text-align: left;
    background-color: #5e245e !important;
    border: #7F4F7F solid 1px !important;
    border-radius: 1.5rem !important;

    span.p-menuitem-icon {
        font-size: 25px;
    }
}

.restaurant-name{
    display: flex !important;
    color: white !important;
    font-size: 25px;
    font-weight: bold;
    justify-content: center !important;
    @media screen and (max-width: 1024px) {
        margin-top: 1%;
    }
}
.p-menu.p-component.customer-action-menu.p-menu-dynamic.p-menu-overlay.p-menu-overlay-visible {
    top: 50px!important;
}

.bottombar{
    background-color: #7F4F7F !important;
    position: fixed;
    display: block;
    bottom: 0;
    height: 80px;
    margin-bottom: 0px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.item-quantity-text {
    color: white;
}

.order-menu-btn{
    background: linear-gradient(to top right, #5E245E, #7F4F7F) !important;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 22px;
    color: white;
    border-radius: 40px;
    transition: 0.5s;
    outline: none !important;
    &:focus, &.p-highlight, &::after{
        outline: none !important;
    }
    &:hover{
        opacity: 0.7;
    }
}
.right{
    right: 0 !important;
    margin-left: auto;
    margin-right: 0px;
}