.sidebar-dish-image {
    width: 100%;
    position: relative;
    height: 25vh;
    .food-picture{
        position: absolute;
        width: 100%;
        max-height: 100%;
        object-fit: cover;
        // margin-left: -8px;
        // width: 106%;
        // height: 400px;
        // margin-right: -8px;
        // z-index: 1;
        // @media(max-width: 1024px){
        //     width: 166%;
        //     height: 500px;
        // }
        // @media(max-width: 768px){
        //     width: 106%;
        //     height: 373px;
        // }
        // @media(max-width: 576px){
        //     width: 146%;
        //     height: 205px;
        // }
    }
}

.food-title{
    font-size: 26px !important;
    @media(max-width: 1024px){
        font-size: 26px !important;
    }
    @media(max-width: 768px){
        font-size: 24px !important;
    }
    @media(max-width: 576px){
        font-size: 20px !important;
    }
    @media(max-width: 360px){
        font-size: 16px !important;
    }
}
.food-item-top {
    padding: 10px;  
    .quantity{
        font-size: 22px;
        font-weight: bold;
        text-decoration: none;
        color: black;
    }
}
.add-to-order-btn {
    // margin-top: 25px;
    font-size: 20px;
    color: white !important;
    border-radius: 40px !important;
    background: linear-gradient(to top right, #5E245E, #7F4F7F) !important;
}
.money-format{
    font-size: 22px;
    margin-top: 4px;
    @media(max-width: 1024px){
        font-size: 24px !important;
    }
    @media(max-width: 768px){
        font-size: 22px !important;
    }
    @media(max-width: 576px){
        font-size: 17px !important;
    }
    @media(max-width: 360px){
        font-size: 15px !important;
    }
}
.input-order{
    margin-bottom: -18px !important;
}
.c-food-item-desc {
    overflow-y: auto;
    text-overflow: ellipsis;
    white-space: pre-line;
    display: -webkit-box;
    max-height: 75px;
    -webkit-box-orient: vertical;
}
.btn-dec{
    background-color: #f84e67 !important;
}
.current-order{
    font-size: 16px;
    text-decoration: none;
    color: black !important;
    margin-top: -15px !important;
}
.food-pairing{
    width: 100%;
    margin-top: -25px;
    margin-bottom: -25px;
}
.promo{
    margin-top: -38px;
    h4{
        margin-top: -15px;
        margin-bottom: -8px;
    }
}
.input-quantity {
    input.p-inputtext.p-component.p-inputnumber-input.p-filled {
        font-size: 18px;
        margin-bottom: -18px !important;
    }
    button.p-inputnumber-button.p-button.p-button-icon-only.p-component.p-button-default.no-radius.btn-sm.p-margin-right-0 {
        font-size: 14px!important;
        border-radius: 30px!important;
        margin-bottom: -18px !important;
    }
}
.p-sidebar-close {
    border-radius: 100% !important;
    right: 10px !important;
}
